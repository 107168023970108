import { Container } from '@chakra-ui/react'
import Head from 'next/head'
import { useEffect } from 'react'

import { s3ImageURL } from '@miimosa/common'

import { Candidate, Hero, Calendar, Partners, Faq } from '@components'

export async function getStaticProps() {
  return {
    props: {},
  }
}

// const steps = [
//   {
//     number: 1,
//     text: (
//       <Text size="lg" maxW="500px">
//         Complétez le formulaire d’inscription{' '}
//         <a href="#form">
//           <strong>ci-dessous</strong>
//         </a>
//         . Un coach projet MiiMOSA reviendra vers vous dans les 48h.
//       </Text>
//     ),
//     imageUrl: 'how-step-1-transition.png',
//   },
//   {
//     number: 2,
//     text: (
//       <Text size="lg" maxW="500px">
//         Lancez votre collecte de financement participatif sur MiiMOSA jusqu’au 31 mai 2023
//       </Text>
//     ),
//     imageUrl: 'how-step-2-transition.png',
//   },
//   {
//     number: 3,
//     text: (
//       <Flex direction="column">
//         <Text size="lg" mb={4} maxW="500px">
//           Profitez des avantages de l’appel à projets* : une dotation financière de 20 000€ financée par les annonceurs
//           partenaires et M6 Publicité et la diffusion d’un programme court** sur M6 et 6play mettant en avant votre
//           projet de transition agricole.
//         </Text>
//       </Flex>
//     ),
//     imageUrl: 'how-step-3-new.png',
//   },
// ]

const Home = () => {
  // Needed because NextLink can't handle anchor if coming from another page
  useEffect(() => {
    const anchor = window.location.hash
    const element = document.getElementById(anchor.startsWith('#') ? anchor.slice(1) : '')
    if (element) element.scrollIntoView()
  })

  // const isMobile = useMountedBreakpoints({ base: true, lg: false }, false)

  return (
    <>
      <Head>
        <title>Cultivons notre avenir</title>
        <meta
          name="description"
          content="M6 Publicité et MiiMOSA s’associent pour soutenir les agriculteurs dans leur transition agricole"
        ></meta>
        <meta property="og:title" content="Cultivons notre avenir"></meta>
        <meta
          property="og:description"
          content="M6 Publicité et MiiMOSA s’associent pour soutenir les agriculteurs dans leur transition agricole"
        ></meta>
        <meta property="og:image" content={s3ImageURL('m6-hero-low.png')}></meta>
      </Head>
      <Container variant="full" py={{ base: 6, md: 14 }}>
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5N848TR"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
        {/* End Google Tag Manager (noscript)*/}
        <Hero />
        <Candidate />
        {/* <Container
          variant="full"
          bgColor="pink"
          id="cta"
          mb={{ base: 10, md: 20 }}
          py={{ base: 10, md: 20 }}
          scrollMarginTop="90px"
        >
          <Container variant="boxed" py={{ base: 0, md: 0 }}>
            {isMobile ? <HowItWorksMobile steps={steps} /> : <HowItWorks steps={steps} />}
          </Container>
        </Container> */}
        <Calendar />
        {/* <Container variant="full" bgColor="pink" id="form">
          <Container variant="boxed">
            <Form />
          </Container>
        </Container> */}
        <Container variant="boxed" id="partners" scrollMarginTop="90px">
          <Partners />
        </Container>
        <Container variant="boxed" id="faq" py={{ base: 0, md: 0 }} scrollMarginTop="90px">
          <Faq />
        </Container>
      </Container>
    </>
  )
}

export default Home
